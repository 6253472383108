import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const ContactUsPage = () => (
  <Layout>
    <SEO description="Contact information for Mina and her care homes." title="Contact Us" />
    <h1>Contact Us</h1>
    <p>
      If you are seeking care home availability or caregiving positions, please give me, Mina, a
      call at the number listed below. Also, please text or call prior to visiting the care homes.
    </p>
    <h3>Mina Abbasvand</h3>
    <div>
      <span>Email: </span>
      <a href="mailto:minavand7@yahoo.com?subject=Mina%27s%20ECH%20-%20">minavand7@yahoo.com</a>
    </div>
    <div>
      <span>Phone: </span>
      <a href="tel:+1(408)348.8361">(408) 348-8361</a>
    </div>
    <hr />
    <h3>Michael Gimeno</h3>
    <div>
      <span>Phone: </span>
      <a href="tel:+1((408)981.1925">(408) 981-1925</a>
    </div>
    <hr />
    <h3>Los Gatos</h3>
    <div>
      <span>Address: </span>
      <address>
        112 Garden Hill Dr.
        <br />
        Los Gatos, CA 95032
      </address>
    </div>
    <div>
      <span>Phone: </span>
      <a href="tel:+1((408)884.8858">(408) 884-8858</a>
    </div>
    <hr />
    <h3>Campbell</h3>
    <div>
      <span>Address: </span>
      <address>
        427 Richlee Dr.
        <br />
        Campbell, CA 95008
      </address>
    </div>
    <div>
      <span>Phone: </span>
      <a href="tel:+1((408)628.4466">(408) 628-4466</a>
    </div>
    <hr />
    <h3>Santa Teresa</h3>
    <div>
      <span>Address: </span>
      <address>
        215 Castillon Way
        <br />
        San Jose, CA 95119
      </address>
    </div>
    <div>
      <span>Phone: </span>
      <a href="tel:+1((408)622.8257">(408) 622-8257</a>
    </div>
  </Layout>
);

export default ContactUsPage;
